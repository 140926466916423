<template>
  <div>
    <v-stepper class="mt-5" v-model="stepFocused">
      <v-stepper-header>
        <v-stepper-step color="error" :complete="stepFocused > 1" step="1">
          Seleziona File
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step color="error" :complete="stepFocused > 2" step="2">
          Conferma i prodotti da scaricare
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step color="error" step="3">
          Elenco prodotti scaricati
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card flat class="mb-12" height="44vh">
            <FileUploader
              color="error"
              @input="handleFileSelection"
              extensionCheck="csv"
              @error-extension="handleErrorExtension"
            ></FileUploader>
            <div
              style="height: 100%; overflow-y: hidden"
              ref="dataTableWrapper"
            >
              <MovementList
                :dataTableHeightValue="dataTableHeightValue"
                :filters="filters"
                :busFilters="busFilters"
              ></MovementList>
            </div>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card flat class="mb-12" height="45vh">
            <UnloadingItemList
              v-if="stepFocused == 2"
              :items-to-unload="itemsFromFile"
              @items-seleted-to-unload="handleSelectionItem"
            ></UnloadingItemList>
          </v-card>
          <div></div>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card flat class="mb-12" height="45vh">
            <ElaboratedItemList
              v-if="stepFocused == 3"
              :items-unloaded="itemsUnloaded"
              :items-in-error="itemsInError"
            ></ElaboratedItemList>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <StandardDialog
      v-model="showFileExtensionDialog"
      dialog-width="360"
      dialog-height="calc(360-1)"
      title="Errore Inserimento File"
      >{{ errorMessage }}</StandardDialog
    >
  </div>
</template>

<script>
import FileUploader from "@/components/common/FileUploader.vue";
import MovementList from "../../movements/MovementList.vue";
import UnloadingItemList from "./UnloadingItemList.vue";
import ElaboratedItemList from "./ElaboratedItemList.vue";
import StandardDialog from "@/components/common/StandardDialog";
import importVocabulary from "@/vocabulary/importer/import.vocabulary.js";
import Vue from "vue";

export default {
  components: {
    FileUploader,
    UnloadingItemList,
    ElaboratedItemList,
    MovementList,
    StandardDialog,
  },
  props: {
    fileToUnload: {
      type: String,
      default: undefined,
    },
    stepFocused: {
      type: Number,
      default: 1,
    },
    itemsFromFile: {
      type: Array,
      default: () => [],
    },
    itemsUnloaded: {
      type: Array,
      default: () => [],
    },
    itemsInError: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      movementUnloadList: undefined,
      dataTableHeightValue: 250,
      showFileExtensionDialog: false,
      errorMessage: undefined,
      filters: {type: "unload"},
      busFilters: new Vue(),
    };
  },
  mounted() {
    this.busFilters.$emit("new-filters", this.filters);
  },
  methods: {
    handleSelectionItem(itemsSelected) {
      this.$emit("selection-multiple-item", itemsSelected);
    },
    handleFileSelection(file) {
      this.$emit("selected-file", file);
    },

    handleErrorExtension() {
      this.showFileExtensionDialog = true;
      this.errorMessage = importVocabulary.FileExtensionNotCorrectCsv.desc;
    },
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
};
</script>

<style scoped>
.right {
  right: 0px;
  margin-right: 5%;
}
</style>