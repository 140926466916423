<template>
  <div>
    <div class="font-italic font-weight-light">
      Seleziona prodotti da scaricare
    </div>

    <ItemToUnloadTable
      :single-select="false"
      :height="dataTableHeight"
      :show-select="true"
      item-key="key"
      fixed-header
      class="mt-1"
      :items="localItemsToUnload"
      :headers="headers"
      :page.sync="page"
      :total-pages.sync="totalPages"
      :show-actions="false"
      item-selected
      loading-text="Caricamento prodotti da scaricare ..."
      :loading="loading"
      :row-per-page="rowsNumberToDisplay"
      :rowPerPageOptions="rowPerPageOptions"
      :disabledPagination="true"
      @input="handleSelection"
    ></ItemToUnloadTable>
  </div>
</template>

<script>
import ItemToUnloadTable from "@/components/common/TypeDataTable.vue";
export default {
  name: "UploadingItemList",
  components: {
    ItemToUnloadTable,
  },
  props: {
    itemsToUnload: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      rowPerPage: 20,
      totalPages: 1,
      dataTableHeightValue: 340,
      itemsSelectedToUnload: undefined,
      localItemsToUnload: undefined,
      rowsNumberToDisplay: 0,
      rowPerPageOptions: [],
      headers: [
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Quantità da Scaricare",
          value: "qtyToUnload",
        },
      ],
    };
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.localItemsToUnload = this.itemsToUnload;
      this.rowsNumberToDisplay = this.localItemsToUnload.length
      this.rowPerPageOptions.push(this.rowsNumberToDisplay)
      this.loading = false;
    }, 1000);
  },

  methods: {
    handleSelection(itemsSelected) {
      this.itemsSelectedToUnload = itemsSelected;
      this.$emit("items-seleted-to-unload", itemsSelected);
    },
  },
};
</script>

<style>
</style>